import {useContext} from "react";
import {SearchesContext} from "../../../context/searches/SearchesState";

export default function Comment({search}) {
    const {updateSearch} = useContext(SearchesContext);

    async function onChange(evt) {
        await updateSearch(search.id, {
            comment: evt.target.value
        });
    }

    return (
        <textarea value={search.comment || ''} className={"form-control"} onChange={onChange}/>
    )
}