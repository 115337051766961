import postalCodeActions from "./postalCodeActions";

export default function postalCodeReducer(state, action) {
    switch (action.type) {
        case postalCodeActions.ADD_POSTALCODE:
            //put postal code alphabetically
            const sortedPostalCodes = [...state.postalCodes, action.payload];
            sortedPostalCodes.sort((a, b) => {
                if (a.municipality < b.municipality) {
                    return -1;
                }
                if (a.municipality > b.municipality) {
                    return 1;
                }
                return 0;
            });

            return {
                ...state,
                postalCodes: sortedPostalCodes,
                createLoading: false
            };
        case postalCodeActions.REMOVE_POSTALCODE:
            return {
                ...state,
                deleteLoading: false,
                postalCodes: state.postalCodes.filter(value => value.id !== action.payload)
            };
        case postalCodeActions.START_CREATING:
            return {
                ...state,
                createLoading: true,
                createError: null
            };
        case postalCodeActions.FAIL_CREATING:
            return {
                ...state,
                createLoading: false,
                createError: action.payload
            };
        case postalCodeActions.START_GETTING:
            return {
                ...state,
                getLoading: true,
                getError: null
            };
        case postalCodeActions.FAIL_GETTING:
            return {
                ...state,
                getLoading: false,
                getError: action.payload
            };
        case postalCodeActions.START_DELETING:
            return {
                ...state,
                deleteLoading: true,
                deleteError: null
            };
        case postalCodeActions.FAIL_DELETING:
            return {
                ...state,
                deleteLoading: false,
                deleteError: action.payload
            };
        case postalCodeActions.SET_POSTALCODES:
            console.log(action.payload)
            return {
                ...state,
                getLoading: false,
                postalCodes: action.payload
            };
        default:
            return state;
    }
};