import {createContext, useContext, useEffect, useReducer, useRef} from "react";
import postalCodeReducer from "./postalCodeReducer";
import postalCodeActions from "./postalCodeActions";
import {AuthContext} from "../AuthContext";

const initialState = {
    postalCodes: [],
    getLoading: false,
    createLoading: false,
    deleteLoading: false,
    getError: null,
    createError: null,
    deleteError: null
};

export const PostalCodeContext = createContext(initialState);

export function PostalCodeProvider({children}) {
    const [state, dispatch] = useReducer(postalCodeReducer, initialState);
    const {token} = useContext(AuthContext);

    useEffect(() => {
        if (token) {
            let isMounted = true;
            const abortController = new AbortController();

            (async () => {
                try {
                    dispatch({
                        type: postalCodeActions.START_GETTING
                    });
                    const response = await fetch(process.env.REACT_APP_URL + '/postalCodes', {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (response.status === 200) {
                        const {postalCodes} = await response.json();
                        console.log(postalCodes);

                        isMounted && dispatch({
                            type: postalCodeActions.SET_POSTALCODES,
                            payload: postalCodes
                        });
                    }
                    else {
                        isMounted && dispatch({
                            type: postalCodeActions.FAIL_GETTING,
                            payload: 'Niet gelukt om de postcodes op te halen..'
                        });
                    }
                }
                catch (e) {
                    console.error(e);
                    isMounted && dispatch({
                        type: postalCodeActions.FAIL_GETTING,
                        payload: 'Niet gelukt om de postcodes op te halen..'
                    });
                }
            })();

            return () => {
                isMounted = false;
                abortController.abort();
            };
        }
    }, [token]);
    async function createPostalCode(postalCode) {
        try {
            dispatch({
                type: postalCodeActions.START_CREATING
            });
            const response = await fetch(process.env.REACT_APP_URL + '/postalCodes', {
                method: "POST",
                body: JSON.stringify(postalCode),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 201) {
                const {postalCode} = await response.json();
                dispatch({
                    type: postalCodeActions.ADD_POSTALCODE,
                    payload: postalCode
                });
            }
            else if (response.status === 409){
                dispatch({
                    type: postalCodeActions.FAIL_CREATING,
                    payload: 'Deze postcode is al toegevoegd.'
                });
            }
            else {
                dispatch({
                    type: postalCodeActions.FAIL_CREATING,
                    payload: 'Niet gelukt om deze postcode toe te voegen.'
                });
            }
        }
        catch (e) {
            console.error(e);
            dispatch({
                type: postalCodeActions.FAIL_CREATING,
                payload: 'Niet gelukt om deze postcode toe te voegen.'
            });
        }
    }

    async function deletePostalCode(postalCodeId) {
        try {
            dispatch({
                type: postalCodeActions.START_DELETING
            });
            const response = await fetch(process.env.REACT_APP_URL + '/postalCodes/' + postalCodeId, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 204) {
                dispatch({
                    type: postalCodeActions.REMOVE_POSTALCODE,
                    payload: postalCodeId
                });
            }
            else {
                dispatch({
                    type: postalCodeActions.FAIL_DELETING,
                    payload: 'Niet gelukt om deze postcode te verwijderen...'
                });
            }
        }
        catch (e) {
            console.error(e);
            dispatch({
                type: postalCodeActions.FAIL_DELETING,
                payload: 'Niet gelukt om deze postcode te verwijderen...'
            });
        }
    }

    return (
        <PostalCodeContext.Provider value={{
            ...state,
            createPostalCode,
            deletePostalCode
        }}>
            {children}
        </PostalCodeContext.Provider>
    )
}