import TableCaption from "./TableCaption";
import TableHead from "./TableHead";
import TableBody from "./TableBody";

export default function SearchesTable({searches, targetElement, loading}) {
    return (
        <div className={"table-responsive"}>
            <table className={"table table-light table-striped"}>
                <TableCaption />
                <TableHead />
                <TableBody searches={searches} targetElement={targetElement} loading={loading}/>
            </table>
        </div>
    );
};