import {createContext, useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../AuthContext";

const initialState = {
    searches: [],
    setSearches:  () => {},
    updateSearch: () => {}
};

export const SearchesContext = createContext(initialState);

export function SearchesProvider({children}) {
    const [searches, setSearches] = useState([]);
    const abortControllerRef = useRef();
    const {token} = useContext(AuthContext);

    async function updateSearch(searchId, search) {
        setSearches(prev => {
            const newS = [...prev];
            newS[newS.findIndex(value => value.id === searchId)] = {
                ...newS[newS.findIndex(value => value.id === searchId)],
                ...search
            };
            return newS;
        });

        try {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            abortControllerRef.current = new AbortController();

            const response = await fetch(process.env.REACT_APP_URL + '/searches/' + searchId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(search),
                signal: abortControllerRef.current.signal
            });
            if (response.status !== 200) {
                throw new Error("Wrong status");
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    async function updateSearchState(searchId, search) {
        setSearches(prev => {
            const newS = [...prev];
            newS[newS.findIndex(value => value.id === searchId)] = {
                ...newS[newS.findIndex(value => value.id === searchId)],
                ...search
            };
            return newS;
        });
    }

    return (
        <SearchesContext.Provider value={{
            searches,
            setSearches,
            updateSearch,
            updateSearchState
        }}>
            {children}
        </SearchesContext.Provider>
    )
}
