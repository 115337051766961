import Container from "./Container";
import {useContext} from "react";
import {PostalCodeContext} from "../context/postalCodes/PostalCodeState";
import CenterContainer from "./CenterContainer";
import Spinner from "./Spinner";

export default function SearchesFilter({filters, setFilters}) {
    const {getLoading, getError, postalCodes} = useContext(PostalCodeContext);

    return (
        <Container>
            <div className="row mb-3">
                <div className={"col"}>
                    <label htmlFor="statusFilter" className={"form-label"}>Status:</label>
                    <select id="statusFilter" className={"form-select"} value={filters.deleted} onChange={evt => {
                        setFilters(prev => {
                            return {
                                ...prev,
                                deleted: evt.target.value
                            };
                        });
                    }}>
                        <option value="">Alle</option>
                        <option value="1">Verwijderd</option>
                        <option value="0">Beschikbaar</option>
                    </select>
                </div>
                <div className={"col"}>
                    <label htmlFor="privateOwnerFilter" className={"form-label"}>Type:</label>
                    <select id="privateOwnerFilter" className={"form-select"} value={filters.privateOwner} onChange={evt => {
                        setFilters(prev => {
                            return {
                                ...prev,
                                privateOwner: evt.target.value
                            };
                        });
                    }}>
                        <option value="">Alle</option>
                        <option value="1">Particulier</option>
                        <option value="0">Makelaar</option>
                    </select>
                </div>
                <div className={"col"}>
                    <label htmlFor="colorFilter" className={"form-label"}>Kleurcode:</label>
                    <select id="colorFilter" className={"form-select"} value={filters.colorCode} onChange={evt => {
                        setFilters(prev => {
                            return {
                                ...prev,
                                colorCode: evt.target.value
                            };
                        });
                    }}>
                        <option value="">Alle</option>
                        <option value="success">Groen</option>
                        <option value="warning">Oranje</option>
                        <option value="danger">Rood</option>
                    </select>
                </div>
                <div className={"col"}>
                    <label htmlFor="postCodeFilter" className={"form-label"}>Postcode:</label>
                    {getLoading ?
                        <CenterContainer><Spinner /></CenterContainer>:
                        <select id="postCodeFilter" className={"form-select"} value={filters.postalCodeId} onChange={evt => {
                            setFilters(prev => {
                                return {
                                    ...prev,
                                    postalCodeId: evt.target.value
                                };
                            });
                        }}>
                            <option value="">Alle</option>
                            {postalCodes.map(value => {
                                return <option key={value.id} value={value.id}>{value.postalCode} ({value.municipality})</option>
                            })}
                        </select>
                    }
                </div>
            </div>

            <input type="text" className={"form-control"} placeholder={"Zoek op adres, prijs, ..."} value={filters.search} onChange={evt => {
                setFilters(prev => {
                    return {
                        ...prev,
                        search: evt.target.value
                    };
                });
            }}/>
        </Container>
    );
};