import Spinner from "../Spinner";
import {useContext, useMemo, useRef, useState} from "react";
import Error from "../Alerts/Error";
import {AuthContext} from "../../context/AuthContext";

export default function CalculateRoute({searches}) {
    const [loading, setLoading] = useState(false);
    const [origin, setOrigin] = useState("");
    const [destination, setDestination] = useState("");
    const [error, setError] = useState(null);
    const [routes, setRoutes] = useState([]);

    const routesRef = useRef();

    const {token} = useContext(AuthContext);

    async function onSubmit(evt) {
        evt.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(process.env.REACT_APP_URL + '/calculateRoute', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    origin, destination,
                    intermediates: searches.map(value => value.address)
                })
            });
            if (response.status === 200) {
                const {routes} = await response.json();
                setRoutes(routes);
            }
            else {
                setError("Niet gelukt om route te berekenen.");
            }
        }
        catch (e) {
            console.error(e);
            setError('Niet gelukt om route te berekenen.');
        }
        finally {
            setLoading(false);
        }
    }

    const urlSearchParams = useMemo(() => {
        const urlSearchParams = new URLSearchParams();

        if (routes.length > 0) {
            urlSearchParams.append('origin', routes[0].startLocation);
            urlSearchParams.append('destination', routes[routes.length - 1].endLocation);

            let wayPoints = [];

            for (let i = 0; i < routes.length; i++) {
                if (i !== routes.length - 1) {
                    wayPoints.push(routes[i].endLocation);
                }
            }

            urlSearchParams.append('waypoints', wayPoints.join('|'));
        }

        return urlSearchParams;
    }, [routes])

    function printRoute() {
        const printWindow = window.open('', '');
        printWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">');
        printWindow.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">');
        printWindow.document.write(routesRef.current.outerHTML);

        printWindow.document.close();

        printWindow.addEventListener("load", () => {
            printWindow.focus();
            printWindow.print();
            // printWindow.close();
        });
    }

    return (
        <>
            <button type="button" className="btn btn-primary" disabled={searches.length === 0} data-bs-toggle="modal" data-bs-target={"#calculateRoute"}>
                Route berekenen
            </button>

            <div className="modal fade modal-xl" id={"calculateRoute"} tabIndex="-1" aria-labelledby={"calculateRouteLabel"} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={"calculateRouteLabel"}>Route berekenen</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="modal-body">
                                {error && <Error message={error} />}
                                <div className={"mb-3"}>
                                    <label htmlFor="origin" className={"form-label"}>Vertek:</label>
                                    <input type="text" id={"origin"} className={"form-control"} required={true} value={origin} onChange={evt => {
                                        setOrigin(evt.target.value);
                                    }}/>
                                </div>
                                <div className={"mb-3"}>
                                    <label htmlFor="destination" className={"form-label"}>Aankomst:</label>
                                    <input type="text" id={"destination"} className={"form-control"} required={true} value={destination} onChange={evt => {
                                        setDestination(evt.target.value);
                                    }}/>
                                </div>

                                <hr />

                                <div className={"text-center"} ref={routesRef}>
                                    {routes.map((route, index) => {
                                        return (
                                            <div key={index}>
                                                <h5>{route.startLocation}</h5>
                                                <h5 className="bi bi-arrow-down"></h5>
                                                <p className={"m-0"}>{route.distance}</p>
                                                <p className={"m-0"}>{route.duration}</p>
                                                <h5 className="bi bi-arrow-down"></h5>
                                                {index === routes.length - 1 && <h5>{route.endLocation}</h5>}
                                            </div>
                                        );
                                    })}
                                </div>

                                <a href={"https://www.google.com/maps/dir/?api=1&" + urlSearchParams.toString()} target={"_blank"} className={"btn btn-primary w-100 " + (routes.length === 0 ? 'disabled': '')}>Link naar google maps</a>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Sluit</button>
                                <button type="button" onClick={printRoute} className="btn btn-secondary" data-bs-dismiss="modal">Print</button>
                                <button type="submit" disabled={loading} className="btn btn-success" >{loading && <Spinner small={true} />} Route berekenen</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};