import TableRow from "./TableRow";
import Spinner from "../Spinner";
import CenterContainer from "../CenterContainer";

export default function TableBody({searches, targetElement, loading}) {
    return (
        <tbody>
        {loading && searches.length > 0 &&
            <tr>
                <td colSpan={10}><Spinner /></td>
            </tr>
            }
            {searches.map(value => {
                return <TableRow search={value} key={value.id} />
            })}
            {loading &&
                <tr>
                    <td colSpan={10}>{loading && <Spinner/>}</td>
                </tr>
            }
            <tr className={loading ? 'd-none': ''}>
                <td colSpan={10} ref={targetElement}></td>
            </tr>
        </tbody>
    );
}