import {useContext, useEffect, useRef} from "react";
import {PostalCodeContext} from "../../context/postalCodes/PostalCodeState";
import useBackDrops from "../../hooks/useBackDrops";
import Error from "../../components/Alerts/Error";
import Spinner from "../../components/Spinner";

export default function RemovePostalCode({postalCode}) {
    const {deletePostalCode, deleteLoading, deleteError} = useContext(PostalCodeContext);
    useBackDrops();

    return (
        <>
            <button type="button" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target={"#deletePostalCode" + postalCode.id}>
                Verwijder
            </button>

            <div className="modal fade" id={"deletePostalCode" + postalCode.id} tabIndex="-1" aria-labelledby={"deletePostalCode" + postalCode.id + "Label"} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={"deletePostalCode" + postalCode.id + "Label"}>Verwijder postcode {postalCode.postalCode}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {deleteError && <Error message={deleteError} />}
                            <span>Bent u zeker dat u deze postcode wilt verwijderen? Alle zoekertjes gelinkt aan deze postcode zullen ook verwijderd worden.</span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Sluit</button>
                            <button type="button" disabled={deleteLoading} className="btn btn-danger" onClick={async () => {
                                await deletePostalCode(postalCode.id);
                            }}>{deleteLoading && <Spinner small={true} />} Verwijder</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}