import {useMemo, useState} from "react";
import SearchesFilter from "../../components/SearchesFilter";
import Spinner from "../../components/Spinner";
import SearchesTable from "../../components/SearchesTable/SearchesTable";
import useSearches from "../../hooks/useSearches";
import Container from "../../components/Container";
import Error from "../../components/Alerts/Error";
import CenterContainer from "../../components/CenterContainer";
import {SortContext} from "../../context/SortContext";
import ExportSearches from "../../components/ExportSearches";
import CalculateRoute from "../../components/CalculateRoute";

export default function Searches() {
    const [filters, setFilters] = useState({
        privateOwner: '',
        postalCodeId: "",
        deleted: "0",
        colorCode: "",
        search: ""
    });

    const [sortBy, setSortBy] = useState("name");
    const [asc, setAsc] = useState(false);
    const [desc, setDesc] = useState(false);

    const {searches, loading, error, targetElement} = useSearches(filters, {
        asc,
        desc,
        sortBy
    });

    const searchesForRouteCalculation = useMemo(() => {
        return searches.filter(value => value.route);
    }, [searches]);

    return (
        <>
            <SearchesFilter filters={filters} setFilters={setFilters}/>
            <Container>
                {error && <Error message={error} />}
                <SortContext.Provider value={{
                    sortBy: sortBy,
                    asc: asc,
                    desc: desc,
                    setSortBy: setSortBy,
                    setAsc: setAsc,
                    setDesc: setDesc
                }}>
                    <div className="d-flex gap-2">
                        <ExportSearches filters={filters} />
                        <CalculateRoute searches={searchesForRouteCalculation}/>
                    </div>

                    <SearchesTable loading={loading} targetElement={targetElement} searches={searches}/>
                </SortContext.Provider>
            </Container>
        </>
    );
};