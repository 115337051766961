import Navbar from "./components/Navbar";
import {PostalCodeProvider} from "./context/postalCodes/PostalCodeState";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import PostalCodes from "./pages/PostalCodes";
import React, {useState} from "react";
import SignIn from "./pages/SignIn";
import {AuthContext} from "./context/AuthContext";
import Searches from "./pages/Searches";

function App() {
    const [token, setToken] = useState(null);

    console.log(token);

    return (
        <BrowserRouter>
          <AuthContext.Provider value={{
              token: token,
              setToken: setToken
          }}>
              {token && <Navbar />}
              <PostalCodeProvider>

              <Routes>
                    {token ?
                        <>
                            <Route path={'/searches'} element={<Searches />} />
                            <Route path={'/postalCodes'} element={<PostalCodes />} />
                        </>
                        :
                         <Route path={'/signIn'} element={<SignIn />} />
                    }

                    <Route path={'*'} element={<Navigate to={token ? '/searches': '/signIn'} />} />
              </Routes>
          </PostalCodeProvider>

        </AuthContext.Provider>

        </BrowserRouter>
    );
}

export default App;
