import {useContext} from "react";
import {SearchesContext} from "../../../context/searches/SearchesState";

export default function Visit({search}) {
    const {updateSearch} = useContext(SearchesContext);

    async function onChange(evt) {
        await updateSearch(search.id, {
            visit: evt.target.checked
        });
    }

    return (
        <input type={"checkbox"} checked={search.visit} className={"form-check"} onChange={onChange}/>
    )
}