import {useContext, useState} from "react";
import Spinner from "../../components/Spinner";
import {AuthContext} from "../../context/AuthContext";
import Container from "../../components/Container";
import Error from "../../components/Alerts/Error";

export default function SignIn() {
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const {setToken} = useContext(AuthContext);

    async function onSubmit(evt){
        evt.preventDefault();
        setError(null);

        try {
            const response = await fetch(process.env.REACT_APP_URL + '/auth', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    password
                })
            });

            if (response.status === 200) {
                const {token} = await response.json();
                setToken(token);
            }
            else {
                setError("Wrong password..");
            }
        }
        catch (e) {
            console.error(e);
            setError("Failed to sing in.");
        }
        finally {
            setLoading(false);
        }
    }
    return (
        <div className={"w-50 mx-auto vh-100 d-flex justify-content-center flex-column"}>
            <Container>
                <div className={"d-flex justify-content-between align-items-center"}>
                    <img src={"/images/Zwart-Goud-Horizontaal.svg"} alt="Logo" height={100} />
                    <div>
                        <h3 className={"m-0"}>La reconquista 1302</h3>
                    </div>
                </div>

                <form onSubmit={onSubmit}>
                    {error && <Error message={error} />}
                    <div className="mb-3">
                        <label htmlFor="password" className={"form-label"}>Password:</label>
                        <input type="password" id={"password"} className={"form-control"} value={password} onChange={evt => {
                            setPassword(evt.target.value);
                        }}/>
                    </div>
                    <button type={"submit"} className={"btn btn-success w-100"} disabled={loading}>{loading && <Spinner small={true} />} Sign in</button>
                </form>
            </Container>
        </div>
    );
}