import {useContext, useState} from "react";
import {PostalCodeContext} from "../../context/postalCodes/PostalCodeState";
import Error from "../../components/Alerts/Error";
import Spinner from "../../components/Spinner";

export default function AddPostalCode() {
    const {createPostalCode, createLoading, createError} = useContext(PostalCodeContext);
    const [postalCode, setPostalCode] = useState({
        postalCode: '',
        municipality: ''
    });

    async function onSubmit(evt) {
        evt.preventDefault();
        await createPostalCode(postalCode)
    }

    return (
        <form onSubmit={onSubmit}>
            {createError && <Error message={createError} />}
            <div className="row mb-3">
                <div className="col">
                    <label htmlFor="postalCodeInput" className={"form-label"}>Postcode:</label>
                    <input type="number" className={"form-control"} id={"postalCodeInput"} required={true} onChange={evt => {
                        setPostalCode(prev => {
                            return {
                                ...prev,
                                postalCode: evt.target.value
                            };
                        });
                    }}/>
                </div>
                <div className="col">
                    <label htmlFor="municipalityInput" className={"form-label"}>Gemeente:</label>
                    <input type="text" id={"municipalityInput"} className={"form-control"} required={true} value={postalCode.municipality} onChange={evt => {
                        setPostalCode(prev => {
                            return {
                                ...prev,
                                municipality: evt.target.value
                            };
                        });
                    }}/>
                </div>
            </div>
            <button className={"btn btn-success w-100"} disabled={createLoading}>{createLoading && <Spinner small={true} />} Voeg postcode toe</button>
        </form>
    );
}