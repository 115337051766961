import Spinner from "./Spinner";
import Error from "./Alerts/Error";

import {useContext, useState} from "react";
import {AuthContext} from "../context/AuthContext";
import {SortContext} from "../context/SortContext";

export default function ExportSearches({filters}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const {token} = useContext(AuthContext);

    const {asc, desc, sortBy} = useContext(SortContext);
    async function exportSearches() {
        setLoading(true);
        setError(null);

        try {
            const urlParams = new URLSearchParams();
            for (const filter in filters) {
                urlParams.append(filter, filters[filter]);
            }

            if (asc || desc) {
                urlParams.append('asc', asc);
                urlParams.append('desc', desc);
                urlParams.append('sortBy', sortBy);
            }

            const response = await fetch(process.env.REACT_APP_URL + '/searches/export?' + urlParams.toString(), {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = "zoekertjes-export.csv";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            }
        }
        catch (e) {
            console.error(e);
            setError('Niet gelukt om de zoekertjes te exporteren...');
        }
        finally {
            setLoading(false);
        }
    }
    return (
        <>
            {error && <Error message={error} />}
            <button className={"btn btn-secondary"} onClick={exportSearches} disabled={loading}>{loading && <Spinner small={true}/>} Export</button>
        </>
    );
};
