import {useContext, useEffect, useRef, useState} from "react";
import {SearchesContext} from "../context/searches/SearchesState";
import {AuthContext} from "../context/AuthContext";

export default function useSearches(filters, {asc, desc, sortBy}) {
    const {searches, setSearches} = useContext(SearchesContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const {token} = useContext(AuthContext);
    const targetElement = useRef(null);

    useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();

        let offset = 0;

        const observer = new IntersectionObserver(async (entries, observer) => {
            if (entries[0].isIntersecting) {
                await fetchSearches(false);
            }
        });

        (async () => {
            await fetchSearches(true);

            if (isMounted) {
                observer.observe(targetElement.current);
            }

        })();

        async function fetchSearches(replace) {
            setLoading(true);
            setError(null);

            try {
                const urlParams = new URLSearchParams();
                for (const filter in filters) {
                    urlParams.append(filter, filters[filter]);
                }

                if (asc || desc) {
                    urlParams.append('asc', asc);
                    urlParams.append('desc', desc);
                    urlParams.append('sortBy', sortBy);
                }

                urlParams.append('offset', offset);

                const response = await fetch(process.env.REACT_APP_URL + '/searches?' + urlParams.toString(), {
                    signal: abortController.signal,
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    const json = await response.json();
                    if (isMounted) {
                        if (replace) {
                            setSearches(json.searches);
                            offset = json.searches.length;
                            if (json.searches.length === 0) {
                                observer.disconnect();
                            }
                        }
                        else {
                            setSearches(prevSearches => {
                                return [...prevSearches, ...json.searches]
                            });
                            if (json.searches.length === 0) {
                                observer.disconnect();
                            }
                            offset += json.searches.length;
                        }
                    }
                }
                else {
                    isMounted && setError("Niet gelukt om de zoekertjes op te halen....");
                }
            }
            catch (e) {
                console.error(e);
                if (e.name !== 'AbortError') {
                    isMounted && setError("Niet gelukt om de zoekertjes op te halen....");
                }
            }
            finally {
                isMounted && setLoading(false);
            }
        }

        return () => {
            isMounted = false;
            abortController.abort();
            observer.disconnect();
        }
    }, [filters, asc, desc, sortBy]);


    
    return {
        loading,
        error,
        searches,
        targetElement
    }
}