import {useContext} from "react";
import {SearchesContext} from "../../../context/searches/SearchesState";

export default function Route({search}) {
    const {updateSearchState} = useContext(SearchesContext);

    async function onChange(evt) {
        await updateSearchState(search.id, {
            route: evt.target.checked
        });
    }

    return (
        <input type={"checkbox"} checked={search.route || false} className={"form-check"} onChange={onChange}/>
    )
}