import {useEffect} from "react";

export default function useBackDrops() {
	useEffect(() => {
		return () => {
			const backdrops = [...document.getElementsByClassName("modal-backdrop fade show")];
			document.body.classList.remove("modal-open");
			document.body.style = null;
			document.body.removeAttribute('data-bs-overflow');
			document.body.removeAttribute('data-bs-padding-right');


			for (let i = 0; i < backdrops.length; i++) {
				backdrops[i].remove();
			}
		}
	}, []);
};
