import SortableHeader from "../SortableHeader";

export default function TableHead() {
    return (
        <thead>
            <tr>
                <th style={{backgroundColor: '#f2c403'}}>Adres</th>
                <th style={{backgroundColor: '#f2c403'}}>Prijs</th>
                <SortableHeader text={"Publicatie datum"} columnName={"publicationDate"} />
                <th style={{backgroundColor: '#f2c403'}}>Telefoonnummers</th>
                <th style={{backgroundColor: '#f2c403'}}>Laatste update</th>
                <th style={{backgroundColor: '#f2c403'}}>Status</th>
                <th style={{backgroundColor: '#f2c403'}}>Gebeld</th>
                <th style={{backgroundColor: '#f2c403'}}>Brochure</th>
                <th style={{backgroundColor: '#f2c403'}}>Bezoek</th>
                <th style={{backgroundColor: '#f2c403'}}>Comment</th>
                <th style={{backgroundColor: '#f2c403'}}>Route</th>
                <th style={{backgroundColor: '#f2c403'}}>Akties</th>
            </tr>
        </thead>
    );
};