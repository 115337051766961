import {NavLink} from "react-router-dom";

export default function Navbar() {
    return (
        <nav className="navbar bg-white shadow-sm border p-3 sticky-top m-3 navbar-expand-lg">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={"/images/Zwart-Goud-Horizontaal.svg"} alt="Logo" height={100} />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink to={'/searches'} className={({isActive}) => {
                                return isActive ? "nav-link active":"nav-link";
                            }}>Zoekertjes</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/postalCodes'} className={({isActive}) => {
                                return isActive ? "nav-link active":"nav-link";
                            }}>Postcodes</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}