import {useContext, useEffect, useRef, useState} from "react";
import {SearchesContext} from "../../../context/searches/SearchesState";
import Error from "../../Alerts/Error";
import Spinner from "../../Spinner";
import useBackDrops from "../../../hooks/useBackDrops";
import Modal from  "bootstrap/js/dist/modal";

export default function DeleteSearch({search}) {
    const {updateSearch} = useContext(SearchesContext);
    const [loading, setLoading] = useState(false);

    useBackDrops();

    async function onClick(evt) {
        setLoading(true);
        await updateSearch(search.id, {
            manualDeleted: true,
            color: null
        });
        setLoading(false);
    }

    return (
        <>
            <button type="button" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target={"#deleteSearch" + search.id}>
                Verwijder
            </button>

            <div className="modal fade" id={"deleteSearch" + search.id} tabIndex="-1" aria-labelledby={"deleteSearch" + search.id + "Label"} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={"deleteSearch" + search.id + "Label"}>Verwijder {search.address}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <span>Bent u zeker dat u dit zoekertje wilt verwijderen? Het zal niet meer up to date gehouden worden met immoweb.</span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Sluit</button>
                            <button type="button" onClick={onClick} disabled={loading} className="btn btn-danger" >{loading && <Spinner small={true} />} Verwijder</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};