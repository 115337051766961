import Flyer from "./Actions/Flyer";
import Comment from "./Actions/Comment";
import Visit from "./Actions/Visit";
import Called from "./Actions/Called";
import DeleteSearch from "./Actions/DeleteSearch";
import Route from "./Actions/Route";

export default function TableRow({search}) {
    return (
        <tr className={"table-" + search.color}>
            <td><a href={search.link} target={"_blank"}>{search.address}</a></td>
            <td>{search.price}</td>
            <td>{search.publicationDate} ({search.days})</td>
            <td>{search.phone1} {search.phone2 && `, ${search.phone2}`}</td>
            <td>{search.updated_at}</td>
            <td>{search.deleted ? 'Verwijderd': search.manualDeleted ? 'Manueel verwijderd': 'Beschikbaar'}</td>
            <td><Called search={search} /></td>
            <td><Flyer search={search} /></td>
            <td><Visit search={search} /></td>
            <td><Comment search={search} /></td>
            <td><Route search={search} /></td>
            <td>{(!search.deleted && !search.manualDeleted) && <DeleteSearch search={search} />}</td>
        </tr>
    );
};