import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import ViewPostalCodes from "./ViewPostalCodes";
import usePostalCodes from "../../hooks/usePostalCodes";
import AddPostalCode from "./AddPostalCode";
import {useContext} from "react";
import {PostalCodeContext} from "../../context/postalCodes/PostalCodeState";
import Error from "../../components/Alerts/Error";
import CenterContainer from "../../components/CenterContainer";

export default function PostalCodes() {
    const {postalCodes, getLoading, getError} = useContext(PostalCodeContext);

    return (
        <>
            <Container>
                <AddPostalCode />
            </Container>
            <Container>
                {getError && <Error message={getError} />}
                {getLoading ? <CenterContainer><Spinner /></CenterContainer>: <ViewPostalCodes postalCodes={postalCodes}/>}
            </Container>
        </>
    );
}