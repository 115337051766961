import {useContext} from "react";
import {SearchesContext} from "../../../context/searches/SearchesState";

export default function Flyer({search}) {
    const {updateSearch} = useContext(SearchesContext);

    async function onChange(evt) {
        await updateSearch(search.id, {
            flyer: evt.target.checked
        });
    }

    return (
        <input type={"checkbox"} checked={search.flyer} className={"form-check"} onChange={onChange}/>
    )
}