import RemovePostalCode from "./RemovePostalCode";

export default function ViewPostalCodes({postalCodes}) {
    return (
        <>
            <table className={"table table-light table-striped"}>
                <caption className={"caption-top"}>Postcodes</caption>
                <thead>
                <tr>
                    <th style={{backgroundColor: '#f2c403'}}>Postcode</th>
                    <th style={{backgroundColor: '#f2c403'}}>Gemeente</th>
                    <th style={{backgroundColor: '#f2c403'}}>Acties</th>
                </tr>
                </thead>
                <tbody>
                {postalCodes.map(value => {
                    return <tr key={value.id}>
                        <td>{value.postalCode}</td>
                        <td>{value.municipality}</td>
                        <td><RemovePostalCode postalCode={value} /></td>
                    </tr>
                })}
                </tbody>
            </table>
        </>
    );
};