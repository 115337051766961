import {useContext} from "react";
import {SearchesContext} from "../../../context/searches/SearchesState";

export default function Called({search}) {
    const {updateSearch} = useContext(SearchesContext);

    async function onChange(evt) {
        await updateSearch(search.id, {
            called: evt.target.checked
        });
    }

    return (
        <input type={"checkbox"} checked={search.called} className={"form-check"} onChange={onChange}/>
    )
}