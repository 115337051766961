export default {
    ADD_POSTALCODE: "ADD_POSTALCODE",
    REMOVE_POSTALCODE: "REMOVE_POSTALCODE",
    START_CREATING: "START_CREATING",
    FAIL_CREATING: "FAIL_CREATING",
    FAIL_GETTING: 'FAIL_GETTING',
    START_GETTING: 'START_GETTING',
    SET_POSTALCODES: 'SET_POSTALCODES',
    START_DELETING: 'START_DELETING',
    FAIL_DELETING: 'FAIL_DELETING'
}